import React, { useState, useEffect } from 'react';
import styles from './Game.module.css';
import clock from '../../images/clock.svg';
import ProgressBar from '@ramonak/react-progress-bar';
import Modal from '../Modals/Modal';
import RedBall from '../RedBall/RedBall';
import BlueBall from '../BlueBall/BlueBall';

export default function Game({ openFinalScreen }) {
  const [timer, setTimer] = useState();
  const [modal, setModal] = useState(false);
  const [firstTouch, setFirstTouch] = useState(true);
  const [zxc, setzxc] = useState(0);

  const startNumbers = [32, 15, 8, 17, 16, 11, 9];
  const numbers = [
    15, 30, 21, 34, 23, 12, 41, 25, 11, 4, 37, 6, 31, 24, 7, 28, 19, 22, 35, 40,
    1, 18, 39, 33, 32, 15, 8, 17, 16, 10, 9, 2, 5, 20, 25, 38, 13, 29, 3, 27,
  ];

  // const numbers1 = [
  //   14, 1, 3, 2, 4, 5, 6, 7, 8, 9, 10, 11, 13, 12, 15, 16, 17, 18, 19, 20, 21,
  //   22, 23, 24, 26, 25, 27, 28, 29, 30, 31, 33, 32, 34, 35, 36, 37, 38, 40, 39,
  //   41,
  // ];

  useEffect(() => {
    const ball = document.getElementById(numbers[zxc]);
    setzxc(zxc + 1);
    if (ball) {
      ball.style.opacity = 1;
    }
  }, [timer]);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0) {
      setModal(true);
    }
  }, [timer]);

  useEffect(() => {
    for (let index = 0; index < 6; index++) {
      const ball = document.getElementById(startNumbers[index]);
      ball.style.opacity = 1;
    }
  }, []);

  const handleFirstTouch = () => {
    firstTouch && setTimer(30);
    setFirstTouch(false);
  };

  return (
    <div className={styles.game}>
      <div className={styles.timer}>
        <p className={styles.text}>Лопните шарики КРАСНОГО цвета</p>
        <div style={{ width: '80%' }}>
          <ProgressBar
            completed={timer * 3}
            bgColor="#A72552"
            borderRadius="36px"
            isLabelVisible={false}
            labelColor="#e80909"
          />
        </div>
        <div className={styles.timerClock}>
          <p className={styles.text}>
            <img src={clock} alt="" />
            {timer}
          </p>
        </div>
      </div>
      <div className={styles.balls}>
        <RedBall id="1" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="2" handleFirstTouch={handleFirstTouch} />
        <RedBall id="3" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="4" handleFirstTouch={handleFirstTouch} />
        <RedBall id="5" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="6" handleFirstTouch={handleFirstTouch} />
        <RedBall id="7" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="8" handleFirstTouch={handleFirstTouch} />
        <RedBall id="9" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="10" handleFirstTouch={handleFirstTouch} />
        <RedBall id="11" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="12" handleFirstTouch={handleFirstTouch} />
        <RedBall id="13" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="14" handleFirstTouch={handleFirstTouch} />
        <RedBall id="15" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="16" handleFirstTouch={handleFirstTouch} />
        <RedBall id="17" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="18" handleFirstTouch={handleFirstTouch} />
        <RedBall id="19" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="20" handleFirstTouch={handleFirstTouch} />
        <RedBall id="21" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="22" handleFirstTouch={handleFirstTouch} />
        <RedBall id="23" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="24" handleFirstTouch={handleFirstTouch} />
        <RedBall id="25" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="26" handleFirstTouch={handleFirstTouch} />
        <RedBall id="27" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="28" handleFirstTouch={handleFirstTouch} />
        <RedBall id="29" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="30" handleFirstTouch={handleFirstTouch} />
        <RedBall id="31" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="32" handleFirstTouch={handleFirstTouch} />
        <RedBall id="33" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="34" handleFirstTouch={handleFirstTouch} />
        <RedBall id="35" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="36" handleFirstTouch={handleFirstTouch} />
        <RedBall id="37" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="38" handleFirstTouch={handleFirstTouch} />
        <RedBall id="39" handleFirstTouch={handleFirstTouch} />
        <BlueBall id="40" handleFirstTouch={handleFirstTouch} />
      </div>
      {modal && <Modal openFinalScreen={openFinalScreen} />}
    </div>
  );
}
