import React, { useState } from 'react';
import styles from './Main.module.css';
import Guide from '../Guide/Guide';

import FinalScreen from '../FinalScreen/FinalScreen';
import Intro from '../Intro/Intro';
import Game from '../Game/Game';

export default function Main() {
  const [guide, setGuide] = useState(true);
  const [intro, setIntro] = useState(false);
  const [game, setGame] = useState(false);
  const [final, setFinal] = useState(false);

  const openIntro = () => {
    setGuide(false);
    setIntro(true);
  };

  const openGame = () => {
    setIntro(false);
    setGame(true);
  };

  const openFinalScreen = () => {
    setGame(false);
    setFinal(true);
  };
  return (
    <div className={styles.main}>
      {guide && <Guide openIntro={openIntro} />}
      {intro && <Intro openGame={openGame} />}
      {game && <Game openFinalScreen={openFinalScreen} />}
      {final && <FinalScreen />}
    </div>
  );
}
