import React from "react";
import styles from "./FinalScreen.module.css";
import bubble from "../../images/bubble.png";

export default function FinalScreen() {
  function closeApp() {
    const tg = window.Telegram.WebApp;
    tg.close();
    localStorage.clear();
  }

  return (
    <div className={styles.final}>
      <div className={styles.container}>
        <img src={bubble} alt="" className={styles.img} />
        <h2 className={styles.title}>Вы молодец!</h2>
      </div>

      <button className={styles.button} onClick={closeApp}>
        к предложениям
      </button>
    </div>
  );
}
