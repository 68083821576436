import React from 'react';
import styles from './Guide.module.css';
import red from '../../images/redball-start.svg';
import blue from '../../images/blueball-start.svg';

export default function Guide({ openIntro }) {
  return (
    <div className={styles.guide}>
      <h1 className={styles.title}>Избавиться от продуктов горения</h1>
      <p className={styles.text}>
        У вас 30 секунд, чтобы лопнуть максимальное количество продуктов горения
        (красные шарики), не задевая синие. Мы в вас верим. Удачи!
        <img src={red} alt="" className={styles.red} />
        <img src={blue} alt="" className={styles.blue} />
      </p>

      <button className={styles.button} onClick={openIntro}>
        Далее
      </button>
    </div>
  );
}
