import React from 'react';
import styles from './Footer.module.css';
import eighteenPlus from '../../images/18+.svg';

export default function Footer() {
  return (
    <div className={styles.footer}>
      <img src={eighteenPlus} alt="18+" />
      <p className={styles.text}>
        ВАЖНО: Не исключает риски. Аэрозоль содержит никотин, вызывающий
        зависимость
      </p>
    </div>
  );
}
