import React, { useState, useEffect } from 'react';
import styles from './Intro.module.css';
import clock from '../../images/clock.svg';
import ProgressBar from '@ramonak/react-progress-bar';

export default function Intro({ openGame }) {
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      openGame();
    }
  }, [timer]);
  return (
    <div className={styles.intro}>
      <div className={styles.timer}>
        <p className={styles.text}>Лопните шарики КРАСНОГО цвета</p>
        <div style={{ width: '80%' }}>
          <ProgressBar
            completed={timer * 3}
            bgColor="#A72552"
            borderRadius="36px"
            isLabelVisible={false}
            labelColor="#e80909"
          />
        </div>
        <div className={styles.timerClock}>
          <p className={styles.text}>
            <img src={clock} alt="" />
            {timer}
          </p>
        </div>
      </div>
      <button className={styles.button} onClick={openGame}>
        Понятно
      </button>
    </div>
  );
}
