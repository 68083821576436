import React, { useState, useEffect } from "react";
import styles from "./Modals.module.css";
import check from "../../images/check.svg";
import { sendEventAnalitic } from "../../utils/api";

export default function Modal({ openFinalScreen }) {
  const [firstModal, setFirstModal] = useState(true);
  const [secondModal, setSecondModal] = useState(false);

  const openSecondModal = () => {
    setFirstModal(false);
    setSecondModal(true);
  };

  useEffect(() => {
    sendEventAnalitic("passed_all_scenario");
  }, []);

  return (
    <>
      {firstModal && (
        <div className={styles.overlayFirst}>
          <div className={styles.container}>
            <div className={styles.checkContainer}>
              <img src={check} alt="" />
            </div>
            <h1 className={styles.title}>Отличная работа</h1>
            <p className={styles.text}>Вы уничтожили большое количество красных шариков!</p>
            <button className={styles.button} onClick={openSecondModal}>
              Далее
            </button>
          </div>
        </div>
      )}
      {secondModal && (
        <div className={styles.overlay}>
          <div className={styles.container}>
            <div className={styles.checkContainer}>
              <img src={check} alt="" />
            </div>
            <p className={styles.text}>При использовании IQOS выделяется на 95% меньше вредных веществ по сравнению с сигаретами*</p>
            <p className={styles.textFrame}>
              Важно: это не означает снижение риска на 95%. Использование IQOS не исключает риски для здоровья
            </p>
            <p className={styles.textSmall}>
              * «на 95% меньше вредных веществ» означает среднее снижение уровней 9 вредных веществ, рекомендованных Всемирной организацией
              здравоохранения для снижения в сигаретном дыме, в число которых не входит никотин. Узнать больше на{" "}
              <a href="https://www.iqos.ru/" target="_blank" style={{ color: "inherit" }}>
                iqos.ru
              </a>
            </p>
          </div>
          <button className={styles.whiteButton} onClick={openFinalScreen}>
            Далее
          </button>
        </div>
      )}
    </>
  );
}
