import React, { useState } from 'react';
import s from './RedBall.module.css';
// import icon from '../../images/greencheck.svg';

export default function RedBall({ id, handleFirstTouch }) {
  // const [img, setImg] = useState(false);

  const handleClick = (e) => {
    if (e.target.style.opacity > 0) {
      handleFirstTouch();
    }

    // setImg(true);
    setTimeout(() => {
      e.target.style.opacity = 0;
      // setImg(false);
    }, 200);
  };

  return (
    <button
      className={s.ball}
      onClick={(e) => {
        handleClick(e);
      }}
      id={id}
    >
      {/* {img && <img src={icon} alt="" className={s.icon} />} */}
    </button>
  );
}
